export const sendMsgToAI = async (msg) => {
  const API_URL = "https://api.openai.com/v1/chat/completions";

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer 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`,
    },
    body: JSON.stringify({
      model: "gpt-4o",
      messages: [{ role: "user", content: msg }],
      // prompt: msg,
      // temperature: 0.2,
      // max_tokens: 2048,
      // n: 1,
      // stop: null,
    }),
  };
  try {
    const response = await (await fetch(API_URL, requestOptions)).json();
    return response?.choices[0]?.message?.content;
  } catch (error) {
    console.log(error);
  }
};
